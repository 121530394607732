export default function (element) {
  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight
  const windowCenterX = windowWidth / 2
  const windowCenterY = windowHeight / 2
  const centerX = element.offsetWidth / 2
  const centerY = element.offsetHeight / 2
  const { left } = element.getBoundingClientRect()
  const { top } = element.getBoundingClientRect()
  return {
    width: element.offsetWidth,
    height: element.offsetHeight,
    centerX,
    centerY,
    distanceFromCenter: windowCenterX - (left + centerX),
    distanceFromCenterY: windowCenterY - (top + centerY),
    left,
    top,
  }
}
