import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    colorMenuData: null,
    selectedColorGroup: null,
    selectedColor: null,
    descriptionOrigin: null,
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    colorMenuData(state) {
      return state.colorMenuData
    },
    selectedColorGroup(state) {
      return state.selectedColorGroup
    },
    selectedColor(state) {
      return state.selectedColor
    },
    descriptionOrigin(state) {
      return state.descriptionOrigin
    },
  },
  modules: {
  },
})
