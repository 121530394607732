<template>
  <div
    ref="colorMenu"
    :class="[iscolumn ? $style.column : '']"
  >
    <div
      v-for="colorGroup in colors"
      :key="colorGroup.name"
    >
      <div
        :class="$style.dotContainer"
        :style="{marginBottom: iscolumn ? '0px' : '16px' }"
      >
        <div
          :class="$style.colorDot"
          :style="{ backgroundColor: colorGroup.value, width: iscolumn ? '25px' : '30px', height: iscolumn ? '25px' : '30px' }"
          class="notSelected"
          @click="openColor($event, colorGroup)"
          @mouseenter="hoverColor($event, colorGroup.name)"
          @mouseout="leaveColor($event, colorGroup.name)"
        />
        <div
          ref="nuanceContainer"
          :class="$style.nuanceContainer"
        >
          <div
            v-for="nuance in colorGroup.colors"
            :key="nuance.name"
            :ref="colorGroup.name"
            :class="$style.nuanceDot"
            :style="{ backgroundColor: nuance.value }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@/data/colors'
import position from '@/utils/position'
import anime from 'animejs'

export default {
  name: 'ColorMenu',
  props: {
    iscolumn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors,
    }
  },
  mounted() {
    const colorMenuData = this.position()
    this.$store.state.colorMenuData = colorMenuData
  },
  methods: {
    position() {
      const { colorMenu } = this.$refs
      return position(colorMenu)
    },
    openColor(event, colorGroup) {
      this.$store.state.descriptionOrigin = position(event.target)
      this.$emit('open', colorGroup)

      if (this.iscolumn) {
        return
      }

      this.$store.state.colorGroup = colorGroup

      document.querySelector('html').style.overflow = 'hidden'
      document.querySelector('html').style.height = '100vw'
      document.querySelector('html').style.backgroundColor = '#232323'

      const dotPosition = position(event.target)

      event.target.classList.add('selected')
      event.target.classList.remove('notSelected')

      const notSelected = document.querySelectorAll('.notSelected')

      const windowWidth = window.innerWidth

      // eslint-disable-next-line no-nested-ternary
      const dotSize = windowWidth > 1200 ? 15
        : windowWidth > 1100 ? 12 : 10

      anime({
        targets: event.target,
        translateX: -(dotPosition.left + dotPosition.centerX),
        translateY: dotPosition.distanceFromCenterY + dotPosition.centerY,
        scale: dotSize,
        duration: 1000,
        easing: 'easeInOutExpo',
        complete: () => {
          this.$router.push({
            name: 'colors',
            params: { colors: colorGroup.name },
          })
        },
      })

      anime({
        targets: notSelected,
        opacity: [1, 0],
        // translateX: [0, -10],
        translateX() {
          return anime.random(-50, 50)
        },
        translateY() {
          return anime.random(-50, 50)
        },
        scale() {
          return anime.random(0.2, 0.5)
        },
        easing: 'easeInOutExpo',
        duration: 500,
        delay: anime.stagger(50),
      })

      const { nuanceContainer } = this.$refs

      anime({
        targets: nuanceContainer,
        keyframes: [
          {
            opacity: 0,
            duration: 800,
          },
        ],
        easing: 'easeInOutExpo',
      })
    },
    hoverColor(event, colorName) {
      if (this.iscolumn) return
      const color = colors.filter(
        (colorGroup) => colorGroup.name === colorName,
      )[0].background
      document.querySelector('html').style.backgroundColor = color
      const dots = this.$refs[colorName]
      anime.remove(dots)
      anime({
        targets: dots,
        opacity: [0, 1],
        translateY: [-10, 0],
        easing: 'easeOutElastic',
        duration: 1000,
        delay: anime.stagger(100),
      })
    },
    leaveColor(event, ref) {
      const dots = this.$refs[ref]
      anime.remove(dots)

      // document.querySelector('body').style.backgroundColor = '#232323'

      anime({
        targets: dots,
        opacity: 0,
        translateY: -10,
        easing: 'easeInOutExpo',
        duration: 500,
        delay: anime.stagger(100),
      })
    },
  },
}
</script>

<style lang="scss" module>
.dotContainer {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 16px;
}

.colorDot {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
}

.colorDotHover {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center;
  transition: all 300ms;
}

.colorDotHover:hover {
  transform: scale(1.3);
  opacity: 0.5;
}

.nuanceContainer {
  position: absolute;
  left: 50px;
  display: flex;
}

.nuanceDot {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  margin-right: 4px;
  opacity: 0;
}

.column {
  display: flex;
  gap: 10px;
}
</style>
