import fari from '@/assets/img/fari.png'
import hmami from '@/assets/img/hmami.png'
import feddi from '@/assets/img/feddi.png'
import rmadi from '@/assets/img/rmadi.jpg'

import zreqlil from '@/assets/img/zreqlil.jpg'
import mniyel from '@/assets/img/mniyel.png'
import bahrawi from '@/assets/img/bahrawi.jpg'
import dowsbah from '@/assets/img/dowsbah.jpg'
import smawi from '@/assets/img/smawi.jpg'
import zenjari from '@/assets/img/zenjari.png'

import ziti from '@/assets/img/ziti.png'
import chibi from '@/assets/img/chibi.png'
import qzbouri from '@/assets/img/qzbouri.png'
import qsbi from '@/assets/img/qsbi.png'
import kherchoufi from '@/assets/img/kherchoufi.png'

import dhbi from '@/assets/img/dhbi.png'
import khabouri from '@/assets/img/khabouri.png'
import mski from '@/assets/img/mski_mastic.jpg'
import qlblbanana from '@/assets/img/qlblbanana.png'
import rasloqida from '@/assets/img/rasloqida.png'
import smni from '@/assets/img/smni.jpg'
import ziwani from '@/assets/img/ziwani.png'

import ltchini from '@/assets/img/ltchini.png'
import lqer3i from '@/assets/img/lqer3i.png'
import cheerjmel from '@/assets/img/cheerjmel.png'
import mchmachi from '@/assets/img/mchmachi.png'
import mrjani from '@/assets/img/mrjani.png'

import remmani from '@/assets/img/remmani.png'
import demlghzal from '@/assets/img/demlghzal.png'
import eekri from '@/assets/img/3ekri.png'
import hejri from '@/assets/img/hejri.png'

import qoqi from '@/assets/img/qoqi.jpg'
import mdadi from '@/assets/img/mdadi.jpg'

const data = [
  {
    name: 'neutral',
    value: '#C4C4C4',
    background: '#232323',
    colors: [{
      id: 'fari',
      name: 'fari',
      value: '#463F32',
      arabic: 'فاري',
      description: 'From the word "far" that means mouse.',
      image: fari,
      audio: 'audio',
    },
    {
      id: 'hmami',
      name: 'hmami',
      value: '#a9afaa',
      arabic: 'حمامي',
      description: 'From the word "hmam" that means pigeon.',
      image: hmami,
      audio: 'audio',
      textColor: '#3A3A3A',
    },
    {
      id: 'feddi',
      name: 'feddi',
      value: '#C0C0C0',
      arabic: 'فضي',
      description: 'From the word "fedda" that means silver',
      image: feddi,
      audio: 'audio',
      textColor: '#3A3A3A',
    },
    {
      id: 'rmadi',
      name: 'rmadi',
      value: '#c0c6c9',
      arabic: 'رمادي',
      description: 'From the word "ramad" that means ash.',
      image: rmadi,
      imageRadius: true,
      audio: 'audio',
      textColor: '#3A3A3A',
    },
    ],
  },
  {
    name: 'blue',
    value: '#83c4ff',
    background: '#1B1D24',
    colors: [{
      id: 'zreqlil',
      name: 'zreq lil',
      value: '#0F056B',
      arabic: 'زرق الليل',
      description: 'Literally means "night blue".',
      image: zreqlil,
      imageRadius: true,
      audio: 'audio',
    },
    {
      id: 'mniyel',
      name: 'mniyel',
      value: '#0000B8',
      arabic: 'منيّل',
      description: 'From the word "nila", which is a reference to indigo. The color comes originally from a natural dye extracted from the leaves of indigo plants. A dye that was used mainly for denim clothes (blue jeans, etc.)',
      image: mniyel,
      audio: 'audio',
    },
    {
      id: 'bahrawi',
      name: 'bahrawi',
      value: '#273BE2',
      arabic: 'بحراوي',
      description: 'From the word "bahr", which means sea.',
      image: bahrawi,
      imageRadius: true,
      audio: 'audio',
    },
    {
      id: 'zenjari',
      name: 'zenjari',
      value: '#009CAB',
      arabic: 'زنجاري',
      description: 'From the word "zanjar", which refers to verdigris. It\'s a common name for a blue-green pigment obtained through the application of acetic acid to copper plates or the natural patina formed when copper, brass or bronze is weathered and exposed to air or seawater over time.',
      image: zenjari,
      audio: 'audio',
    },
    {
      id: 'shibi',
      name: 'shibi',
      value: '#9cd2f8',
      arabic: 'شيبي',
      description: 'From shiba, which means absinthe or wormwood.',
      image: chibi,
      textColor: '#0F1D49',
      audio: '',
    },
    {
      id: 'smawi',
      name: 'smawi',
      value: '#87CEEB',
      arabic: 'سماوي',
      description: 'From the word "sma", or "samae" in Arabic, which means sky.',
      image: smawi,
      audio: 'audio',
      imageRadius: true,
      textColor: '#0F1D49',
    },
    {
      id: 'dowsbah',
      name: 'dow sbah',
      value: '#B9D9EB',
      arabic: 'ضوء الصباح',
      description: 'Literally means "morning light", which refers to the blue tones present in the sky in an early sunny morning.',
      image: dowsbah,
      audio: 'audio',
      imageRadius: true,
      textColor: '#0F1D49',
    },
    ],
  },
  {
    name: 'green',
    value: '#83FFA6',
    background: '#1B2423',
    colors: [{
      id: 'ziti',
      name: 'ziti',
      value: '#708D23',
      arabic: 'زيتي',
      description: 'From the Moroccan word "zit", which means oil.',
      image: ziti,
      audio: 'audio',
    },
    {
      id: 'qsbi',
      name: 'qsbi',
      value: '#6aa84f',
      arabic: 'قصبي',
      description: 'From the Moroccan word "qseb", which refers to the cane plant.',
      image: qsbi,
      audio: 'audio',
    },
    {
      id: 'qzbouri',
      name: 'qzbouri',
      value: '#C8CFB4',
      arabic: 'قزبوري',
      description: 'From the Moroccan word qzbour, that refers to coriander.',
      image: qzbouri,
      audio: 'audio',
      textColor: '#0F4913',
    },
    {
      id: 'kherchoufi',
      name: 'kherchoufi',
      value: '#F0FFF0',
      arabic: 'خرشوفي',
      description: 'From the word "kherchouf", which refers to cardoon. Found in the wild along the Mediterranean, from Morocco and Portugal to Libya and Croatia, a cardoon is a thistle that tastes like a bitter version of a giant artichoke with small, prickly flower heads. But unlike an artichoke, you eat the stems, not the flower buds.',
      image: kherchoufi,
      audio: 'audio',
      textColor: '#0F4913',
    },
    ],
  },
  {
    name: 'yellow',
    value: '#ECDE92',
    background: '#1C1B15',
    colors: [{
      id: 'dhbi',
      name: 'dhbi',
      value: '#FFD700',
      arabic: 'ذهبي',
      description: 'From the word "dhb", which means gold.',
      image: dhbi,
      audio: 'audio',
      textColor: '#493F0F',
    },
    {
      id: 'rasloqida',
      name: 'ras loqida',
      value: '#fcf508',
      arabic: 'راس لوقيدة',
      description: 'Literally means "match\'s head". It refers to the sulfur used in matches. Sulfur having a yellow color. This color name can also refer to a blue color, as the match\'s head has seen some color change from yellow to blue over time.',
      image: rasloqida,
      audio: 'audio',
      textColor: '#493F0F',
    },
    {
      id: 'khabouri',
      name: 'khabouri',
      value: '#FFFF05',
      arabic: 'خابوري',
      description: 'Refers to "zahrat al khaboor", otherwise known as Spanish broom. A type of flower found along the Mediterranean and was mainly used in textile production and as sheep food.',
      image: khabouri,
      audio: 'audio',
      textColor: '#493F0F',
    },
    {
      id: 'ziwani',
      name: 'ziwani',
      value: '#F7E35F',
      arabic: 'زيواني',
      description: 'A word used to refer to the iconic yellow used on the Moroccan slippers called "belgha".',
      image: ziwani,
      audio: 'audio',
      textColor: '#493F0F',
    },
    {
      id: 'smni',
      name: 'smni',
      value: '#F0E36B',
      arabic: 'سمني',
      description: 'From the word "smen", a salted fermented butter, used mostly as an ingredient in Middle Eastern cuisine and North African cooking.',
      image: smni,
      audio: 'audio',
      imageRadius: true,
      textColor: '#493F0F',
    },
    {
      id: 'mski',
      name: 'mski',
      value: '#fff1b5',
      arabic: 'مسكي',
      description: 'From "meska" which refers to mastic a resin obtained from the mastic tree, a tree that is mainly found in the mediterranean. It is also known as tears of Chios, being traditionally produced on the island Chios in Greece. It can be used for flavoring food, mainly pastries, or as an encens and also as chewing-gum.',
      image: mski,
      imageRadius: true,
      audio: '',
      textColor: '#493F0F',
    },
    {
      id: 'qlblbanana',
      name: 'qlb lbanana',
      value: '#ffffba',
      arabic: 'قلب البنانة',
      description: 'Literally means "banana\'s inside".',
      image: qlblbanana,
      audio: 'audio',
      textColor: '#493F0F',
    },
    ],
  },
  {
    name: 'orange',
    value: '#F09973',
    background: '#1C1915',
    colors: [
      {
        id: 'lmrjani',
        name: 'lmrjani',
        value: '#E73E01',
        arabic: 'المرجاني',
        description: 'From the Arabic word Marjan, which means coral. The coral color blends three warm colors : orange, pink, and red.',
        image: mrjani,
        audio: 'audio',
      },
      {
        id: 'lqerii',
        name: 'lqerʿii',
        value: '#FF7518',
        arabic: 'القرعي',
        description: 'From the word qarʿaa or garʿaa. It specifically refers to garʿaa lḥamra, a squash that has orange flesh.',
        image: lqer3i,
        audio: 'audio',
      },
      {
        id: 'ltshini',
        name: 'ltshini',
        value: '#FF7F00',
        arabic: 'التشيني',
        description: 'From the word "tchin", that refers to the orange fruit.',
        image: ltchini,
        audio: 'audio',
      },
      {
        id: 'lmshmashi',
        name: 'lmshmashi',
        value: '#f2932e',
        arabic: 'المشماشي',
        description: 'From the word "mechmach", which means apricot.',
        image: mchmachi,
        audio: 'audio',
      },
      {
        id: 'sheerjmel',
        name: 'shʿeer jmel',
        value: '#D99058',
        arabic: 'شعر الجمل',
        description: 'Literally means "camel\'s hair".',
        image: cheerjmel,
        audio: 'audio',
      },
    ],
  },
  {
    name: 'red',
    value: '#D57A77',
    background: '#1C1615',
    colors: [{
      id: 'remmani',
      name: 'remmani',
      value: '#6E0B14',
      arabic: 'رماني',
      description: 'From the word "remman" which means pomegranate.',
      image: remmani,
      audio: 'audio',
    },
    {
      id: 'demlghzal',
      name: 'dem lghzal',
      value: '#800000',
      arabic: 'دم لغزال',
      description: 'Literally means gazel\'s blood.',
      image: demlghzal,
      audio: 'audio',
    },
    {
      id: 'aakri',
      name: 'aakri',
      value: '#960018',
      arabic: 'عكري',
      description: 'From aakar lfassi, made from red poppy petals and used as a coloration for lips and cheeks.',
      image: eekri,
      audio: 'audio',
    },
    {
      id: 'hejri',
      name: 'hejri',
      value: '#872E1B',
      arabic: 'حجري',
      description: 'From the Arabic word 7ajar, which means rock. But here it refers to the colors of bricks used in the construction of houses.',
      image: hejri,
      audio: 'audio',
    },
    ],
  },
  {
    name: 'purple',
    value: '#A677D5',
    background: '#18151C',
    colors: [{
      id: 'mdadi',
      name: 'mdadi',
      value: '#6C3082',
      arabic: 'مدادي',
      description: 'from the word "mdad", which means ink. The Romans had a word "encaustunt" for their purple ink with which they signed their edicts, a literal translation of the word means “burnt ink”. The French for ink is “encre”, or in Old French “enqwe” and the old English word is enke or ilzke; all of these words are probably a shorter version of encaustunt.',
      image: mdadi,
      imageRadius: true,
      audio: 'audio',
    },
    {
      id: 'qoqi',
      name: 'qoqi',
      value: '#B666D2',
      arabic: 'قوقي',
      description: 'From the word "qoq" which means artichoke.',
      image: qoqi,
      imageRadius: true,
      audio: 'audio',
    },
    // {
    //   id: 'hrflouz',
    //   name: 'hrf louz',
    //   value: '#FAE6FA',
    //   arabic: 'حرف اللوز',
    //   description: 'description',
    //   image: 'image',
    //   audio: 'audio',
    //   textColor: '#360F49',
    // },
    ],
  },
]

export default data
