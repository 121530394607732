<template>
  <div class="home">
    <div
      ref="mainView"
      :class="$style.mainView"
    >
      <div :class="$style.logoWrapper">
        <div
          ref="topText"
          style="margin-bottom: 16px; opacity: 0"
        >
          Welcome to
        </div>
        <Logo
          :class="$style.logo"
          :transitioning="transitioning"
        />
        <div
          ref="bottomText"
          style="margin-top: 16px; opacity: 0"
        >
          Discover Moroccan color names by selecting a color group
        </div>
      </div>
      <ColorMenu @open="colorSelect" />
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo'
import ColorMenu from '@/components/ColorMenu'
import anime from 'animejs'

export default {
  name: 'Home',
  components: {
    Logo,
    ColorMenu,
  },
  data() {
    return {
      transitioning: false,
    }
  },
  computed: {
    colorMenuData() {
      return this.$store.getters.colorMenuData
    },
  },
  watch: {
    colorMenuData(newValue) {
      if (newValue) {
        this.animateMain()
      }
    },
  },
  methods: {
    animateMain() {
      const { mainView } = this.$refs
      const { topText } = this.$refs
      const { bottomText } = this.$refs

      const colorMenuDistance = this.colorMenuData.distanceFromCenter
      const windowWidth = window.innerWidth

      if (windowWidth < 700) {
        anime({
          targets: mainView,
          keyframes: [
            {
              opacity: [0, 1],
              duration: 800,
            },
          ],
          easing: 'easeInOutExpo',
        })
      } else {
        anime({
          targets: mainView,
          keyframes: [
            {
              opacity: [0, 1],
              duration: 800,
            },
            { translateX: colorMenuDistance, duration: 1000 },
          ],
          easing: 'easeInOutExpo',
        })
      }

      anime({
        targets: topText,
        keyframes: [
          {
            opacity: [0, 1],
            translateY: ['-10px', '0px'],
            duration: 500,
            delay: 1200,
          },
        ],
        easing: 'easeInOutExpo',
      })

      anime({
        targets: bottomText,
        keyframes: [
          {
            opacity: [0, 1],
            translateY: ['10px', '0px'],
            duration: 500,
            delay: 1400,
          },
        ],
        easing: 'easeInOutExpo',
      })
    },
    colorSelect() {
      this.transitioning = true

      const { topText } = this.$refs
      const { bottomText } = this.$refs
      anime({
        targets: topText,
        keyframes: [
          {
            opacity: 0,
            duration: 800,
          },
        ],
        easing: 'easeInOutExpo',
      })
      anime({
        targets: bottomText,
        keyframes: [
          {
            opacity: 0,
            duration: 800,
          },
        ],
        easing: 'easeInOutExpo',
      })
    },
  },
}
</script>

<style lang="scss" module>
.mainView {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-right: 80px;
  height: 50px;
}

@media (max-width: 1020px) {
  .mainView {
    flex-direction: column;
  }
  .logo {
    margin-right: 0px;
    height: 50px;
    width: 60%;
  }

  .logoWrapper {
    margin-bottom: 50px;
    text-align: center;
    font-size: 13px;
  }
}
</style>
