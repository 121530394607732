<template>
  <div :class="$style.wrapper">
    <span
      class="close rounded black"
      :class="$style.closeButton"
      @click="$emit('close')"
    />
    <div :class="$style.content">
      <h2 :class="$style.title">
        Why?
      </h2>

      <p :class="$style.text">
        Moroccolor is a small project born from a discussion I once had with my
        mom. I was wondering why in the Moroccan language there is a lack of
        nuance in color names. To which she replied that it wasn't actually the
        case before. Color names were more nuanced and mainly refered to very
        concrete things.
        <br><br>
        So I thought it might be a good idea to do some research about this
        matter and make a website that would keep these words alive in some way
        or another. So we (my mom and me) tried to gather as much information
        about these words as we could.
      </p>

      <h2 :class="$style.title">
        Inaccuracies?
      </h2>

      <p :class="$style.text">
        Of course colors are a very subjective thing. It's actually very hard to
        convey what a color exactly is. A same color name might refer to a
        different color nuance depending on the country, culture, and time. It
        can even be different within a country, especially in a country as
        culturally rich and diverse as Morocco.
        <br><br>
        So I'm aware that the color values that are presented in this website
        might be inaccurate. This is why I decided to add a description and
        image to what the color refers to, to alleviate the inaccuracy as much
        as possible.
      </p>

      <h2 :class="$style.title">
        Contribute?
      </h2>

      <p :class="$style.text">
        Do you know other color names that I can add? Do you want to suggest a
        different color value for a color name? Do you have better references?
        You can contact me in any of the following ways :
      </p>
      <div :class="$style.logos">
        <a
          href="https://www.instagram.com/aminerman/"
          target="_blank"
        >
          <img
            :src="instagram"
            alt=""
          ></a>

        <a
          href="https://m.me/aminerman"
          target="_blank"
        >
          <img
            :src="messenger"
            alt=""
          ></a>

        <a
          href="mailto:amine.bouyarmane@gmail.com"
          target="_blank"
        >
          <img
            :src="google"
            alt=""
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import google from '@/assets/logos/google.png'
import messenger from '@/assets/logos/messenger.png'
import instagram from '@/assets/logos/instagram.png'

export default {
  data() {
    return {
      google,
      messenger,
      instagram,
    }
  },
}
</script>

<style lang="scss" module>
.wrapper {
  height: calc(100vh - 80px);
  width: 90vw;
  overflow: hidden;
  background-color: white;
  color: #333;
  position: relative;
  border-radius: 10px;
  transform: translateZ(0);
}

.content {
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: calc(100% - 80px);
  overflow-y: auto;
}

.content::-webkit-scrollbar {
  width: 7.5px;
}

.content::-webkit-scrollbar-track {
  background: white;
}

.content::-webkit-scrollbar-thumb {
  background: #7f7f7f;
  border-right: 1px solid white;
  border-radius: 5px;
}

.title {
  text-align: center;
  font-size: 40px;
  margin-top: 20px;
}

.text {
  max-width: 600px;
  margin: 32px 0;
  line-height: 20px;
  width: 90%;
}

.closeButton {
  position: fixed;
  top: 16px;
  right: 16px;
  cursor: pointer;
  z-index: 10;
}

.logos {
  max-width: 600px;
  width: 90%;
  display: flex;
  justify-content: space-around;

  img {
    height: 40px;
  }
}
</style>
