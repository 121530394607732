<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 534.3 70"
    :class="$style.logo"
    @click="clicked"
  >
    <g id="logoGroup">
      <path
        d="M54 10L31.6 41.1c-.5.8-1.3 1.1-2 1.1h-.2c-.9 0-1.6-.3-2.1-1.1l-22.4-31v57.5c0 1.3-1.1 2.4-2.4 2.4C1.1 70 0 68.9 0 67.6V2.8C0 2 .4 1.3 1 .9c0-.1.1-.1.2-.2 1.1-.7 2.6-.5 3.4.6l24.9 34.5L54.3 1.3C55 .2 56.6 0 57.7.7c.3.3.5.5.7.8.2.4.4.9.4 1.3v64.8c0 1.3-1.1 2.4-2.4 2.4-1.4 0-2.4-1.1-2.4-2.4V10z"
      />
      <path
        d="M104.2 21c12.9 0 23.2 11.2 23.2 24.5S117.1 70 104.2 70C91.4 70 81 58.8 81 45.5S91.4 21 104.2 21zm0 44.2c10 0 18.4-8.8 18.4-19.7 0-10.9-8.4-19.8-18.4-19.8-10 0-18.4 8.9-18.4 19.8s8.4 19.7 18.4 19.7z"
      />
      <path
        d="M143.8 23.5c0-1.4 1.1-2.4 2.5-2.4 1.2 0 2.3 1 2.3 2.4v10.8c.2-.4.4-.8.7-1.2 3.7-5.8 10.2-12 19.6-12 1.3 0 2.4 1 2.4 2.4 0 1.3-1.1 2.4-2.4 2.4-6.4 0-10.8 3.6-14.2 8-3.3 4.4-5.2 9.4-5.9 11.4-.2.4-.2.6-.2.8v21.5c0 1.4-1.1 2.4-2.3 2.4-1.4 0-2.5-1-2.5-2.4V23.5z"
      />
      <path
        d="M206.1 21c12.9 0 23.2 11.2 23.2 24.5S219 70 206.1 70c-12.8 0-23.2-11.2-23.2-24.5S193.3 21 206.1 21zm0 44.2c10 0 18.4-8.8 18.4-19.7 0-10.9-8.4-19.8-18.4-19.8-10 0-18.4 8.9-18.4 19.8s8.4 19.7 18.4 19.7z"
      />
      <path
        d="M244.2 45.4c0-13.6 11.2-24.4 25.2-24.4 5.8 0 11.3 2 15.6 5.4 1 .7 1.2 2.2.3 3.3-.8.9-2.2 1.2-3.3.4-3.5-2.8-7.9-4.4-12.6-4.4-11.4 0-20.4 8.9-20.4 19.7 0 11 9 19.8 20.4 19.8 4.7 0 9.1-1.6 12.6-4.3 1.1-.8 2.5-.5 3.3.4.9 1.1.7 2.6-.5 3.5-4.1 3.2-9.6 5.2-15.4 5.2-14 0-25.2-10.8-25.2-24.6z"
      />
      <path
        d="M299.8 45.4C299.8 31.8 311 21 325 21c5.8 0 11.3 2 15.6 5.4 1 .7 1.2 2.2.3 3.3-.8.9-2.2 1.2-3.3.4-3.5-2.8-7.9-4.4-12.6-4.4-11.4 0-20.4 8.9-20.4 19.7 0 11 9 19.8 20.4 19.8 4.7 0 9.1-1.6 12.6-4.3 1.1-.8 2.5-.5 3.3.4.9 1.1.7 2.6-.5 3.5-4.1 3.2-9.6 5.2-15.4 5.2-14 0-25.2-10.8-25.2-24.6z"
      />
      <path
        d="M379.8 21c12.9 0 23.2 11.2 23.2 24.5S392.7 70 379.8 70c-12.8 0-23.2-11.2-23.2-24.5S367 21 379.8 21zm0 44.2c10 0 18.4-8.8 18.4-19.7 0-10.9-8.4-19.8-18.4-19.8-10 0-18.4 8.9-18.4 19.8s8.4 19.7 18.4 19.7z"
      />
      <path
        d="M422.1 2.4c0-1.3 1.1-2.4 2.4-2.4 1.4 0 2.5 1.1 2.5 2.4v65.2c0 1.3-1.1 2.4-2.5 2.4-1.3 0-2.4-1.1-2.4-2.4V2.4z"
      />
      <path
        d="M467.2 21c12.9 0 23.2 11.2 23.2 24.5S480.1 70 467.2 70C454.4 70 444 58.8 444 45.5S454.4 21 467.2 21zm0 44.2c10 0 18.4-8.8 18.4-19.7 0-10.9-8.4-19.8-18.4-19.8-10 0-18.4 8.9-18.4 19.8s8.4 19.7 18.4 19.7z"
      />
      <path
        d="M506.8 23.5c0-1.4 1.1-2.4 2.5-2.4 1.2 0 2.3 1 2.3 2.4v10.8c.2-.4.4-.8.7-1.2 3.7-5.8 10.2-12 19.6-12 1.3 0 2.4 1 2.4 2.4 0 1.3-1.1 2.4-2.4 2.4-6.4 0-10.8 3.6-14.2 8-3.3 4.4-5.2 9.4-5.9 11.4-.2.4-.2.6-.2.8v21.5c0 1.4-1.1 2.4-2.3 2.4-1.4 0-2.5-1-2.5-2.4V23.5z"
      />
      <circle
        cx="103.91"
        cy="45.97"
        r="10"
        fill="#2885c3"
      />
      <polygon
        points="211.82,36.44 200.55,36.44 194.91,46.21 200.55,55.97 211.82,55.97 217.46,46.21"
        fill="#00a79d"
      />
      <polygon
        points="380.51,35.89 369.91,54.24 391.1,54.24"
        fill="#f59883"
      />
      <rect
        x="459.79"
        y="38.85"
        transform="rotate(-45 466.8911 45.9546)"
        width="14.2"
        height="14.2"
        fill="#fff799"
      />
    </g>

  </svg>
</template>

<script>
import anime from 'animejs'

export default {
  name: 'Logo',
  props: {
    transitioning: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    transitioning(newValue) {
      if (newValue) {
        const letters = document.querySelectorAll('#logoGroup > *')

        anime({
          targets: letters,
          opacity: [1, 0],
          translateX() {
            return anime.random(-50, 50)
          },
          translateY() {
            return anime.random(-50, 50)
          },
          scale() {
            return anime.random(0.2, 0.5)
          },
          easing: 'easeInOutExpo',
          duration: 500,
          delay: anime.stagger(10),
        })
      }
    },
  },
  mounted() {
    this.animate()
  },
  methods: {
    animate() {
      const letters = document.querySelectorAll('#logoGroup > *')
      anime({
        targets: letters,
        keyframes: [
          {
            translateY() {
              return [anime.random(-50, 50), 0]
            },
            opacity: [0, 1],
            duration() {
              return 800
            },
          },
        ],
        easing: 'easeInOutExpo',
        delay: anime.stagger(50),
      })
    },
    clicked() {
      this.$router.push('/')
      document.querySelector('html').style.backgroundColor = '#232323'
    },
  },
}
</script>

<style lang="scss" module>
.logo {
  opacity: 1;
  fill: white;
  overflow: visible;
}
</style>
