
<template>
  <div
    :class="$style.container"
    @click="copy"
  >
    <div
      ref="copied"
      :class="$style.copied"
    >
      Copied
    </div>
    <input
      ref="clone"
      readonly
      :value="text"
      :class="$style.input"
      @focus="$event.target.select()"
    >
    <div>
      {{ text }}
    </div>
  </div>
</template>

<script>
import anime from 'animejs'

export default {
  name: 'CopyToClipboard',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  methods: {
    copy() {
      this.$refs.clone.focus()
      document.execCommand('copy')
      this.$emit('copied')

      const { copied } = this.$refs

      anime({
        targets: copied,
        keyframes: [
          {
            translateY() {
              return [-20, -40]
            },
            opacity: [1, 0],
            duration() {
              return 800
            },
          },
          {
            opacity: 0,
          },
        ],
        easing: 'easeOutExpo',
      })
    },
  },
}
</script>

<style lang="scss" module>
.input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
}

.container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.copied {
    position: absolute;
    opacity: 0;
    margin: auto;
    color: #333;
}
</style>
