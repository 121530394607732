<template>
  <div>
    <div
      :class="$style.sideDot"
      :style="{ backgroundColor: selectedColorGroup[0].value }"
    />
    <div
      ref="header"
      :class="$style.header"
    >
      <Logo :class="$style.logo" />
      <ColorMenu
        ref="colorMenu"
        :iscolumn="true"
        :class="$style.menu"
        @open="change"
      />
    </div>
    <div
      ref="footer"
      :class="$style.footer"
    >
      <ColorMenu
        :iscolumn="true"
        :class="$style.menuFooter"
        @open="change"
      />
    </div>
    <div
      v-show="isColorOpen"
      :class="$style.detailView"
    >
      <div :class="[$style.container, $style.detailContainer]">
        <div
          v-if="isColorOpen"
          :class="$style.overlay"
          @click="close"
        />
        <router-view @close="close" />
      </div>
    </div>

    <div :class="[$style.content, $style.container]">
      <div
        style="width: 100%"
        :class="$style.contentWrapper"
      >
        <div
          v-for="color in selectedColorGroup[0].colors"
          :key="color.name"
          ref="colorCard"
          :style="{ backgroundColor: color.value, color: color.textColor }"
          :class="$style.colorCard"
          @click="openColor($event, color)"
        >
          <div>
            <div :class="$style.name">
              {{ color.name }}
            </div>
            <div
              class="arabic"
              :class="$style.nameArabic"
            >
              {{ color.arabic }}
            </div>
          </div>

          <div :class="$style.colorValue">
            {{ color.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo'
import colors from '@/data/colors'
import anime from 'animejs'
import ColorMenu from '@/components/ColorMenu'

export default {
  name: 'ColorGroup',
  components: {
    Logo,
    ColorMenu,
  },
  computed: {
    selectedColorGroup() {
      const group = this.$store.getters.selectedColorGroup
        || colors.filter(
          (colorGroup) => colorGroup.name === this.$route.params.colors,
        )
      return group
    },
    isColorOpen() {
      return !!this.$route.params.color
    },
  },
  watch: {
    selectedColorGroup(newValue, oldValue) {
      const newColor = newValue[0].name
      const oldColor = oldValue[0].name
      if (oldColor === newColor && newColor === this.$route.params.colors) {
        return
      }
      setTimeout(() => {
        this.enterAnimation()
      }, 0)
    },
  },
  mounted() {
    this.enterAnimation()
  },
  methods: {
    change(colorGroup) {
      if (this.selectedColorGroup[0].name === colorGroup.name) return
      this.leaveAnimation()

      setTimeout(() => {
        this.$store.state.colorGroup = colorGroup

        this.$router.push({
          name: 'colors',
          params: { colors: colorGroup.name },
        })
      }, 800)
    },
    enterAnimation() {
      setTimeout(() => {
        document.querySelector('html').style.backgroundColor = this.selectedColorGroup[0].background
        this.$refs.header.style.backgroundColor = this.selectedColorGroup[0].background
        this.$refs.footer.style.backgroundColor = this.selectedColorGroup[0].background
      }, 100)

      const cards = this.$refs.colorCard
      anime({
        targets: cards,
        keyframes: [
          {
            translateY() {
              return [anime.random(-50, 50), 0]
            },
            opacity: [0, 1],
            duration() {
              return 800
            },
          },
        ],
        easing: 'easeInOutExpo',
        delay: anime.stagger(30),
      })
    },
    leaveAnimation() {
      const cards = this.$refs.colorCard
      anime({
        targets: cards,
        keyframes: [
          {
            translateY() {
              return [0, anime.random(-50, 50)]
            },
            opacity: [1, 0],
            duration() {
              return 800
            },
          },
        ],
        easing: 'easeInOutExpo',
        delay: anime.stagger(30),
      })
    },
    openColor(event, color) {
      this.$store.state.selectedColor = color

      this.$router.push({ name: 'color', params: { color: color.id } })
      // const dotPosition = position(event.target)
      // anime({
      //   targets: event.target,
      //   translateX: -dotPosition.left,
      //   translateY: dotPosition.distanceFromCenterY,
      //   scale: 7,
      //   duration: 800,
      //   easing: 'easeInOutExpo',
      // })
    },
    close() {
      document.querySelector('body').style.overflowY = 'auto'

      this.$router.push({
        name: 'colors',
        params: { colors: this.$route.params.colors },
      })
    },
  },
}
</script>

<style lang="scss" module>
.container {
  max-width: 860px;
  width: 100%;
  margin: auto;
}

.logo {
  height: 30px;
  margin-right: 24px;
  cursor: pointer;
}

.colorCard {
  width: 12.5%;
  height: 135px;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 40px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  opacity: 0;
  cursor: pointer;
}

.colorCard:nth-of-type(1) {
  margin-left: 0;
}
.colorCard:nth-of-type(5n) {
  margin-right: 0;
}
.colorCard:nth-of-type(6n) {
  margin-left: 0;
}
.colorCard:last-of-type {
  margin-right: 0;
}

@media (max-width: 1400px) {
  .container {
    max-width: 670px;
  }

  .colorCard {
    width: 15.6%;
  }

  .colorCard:nth-of-type(4n) {
    margin-right: 0px;
  }

  .colorCard:nth-of-type(5n) {
    margin-right: 20px;
    margin-left: 0px;
  }

  .colorCard:nth-of-type(6n) {
    margin-left: 20px;
  }
}

@media (max-width: 1050px) {
  .container {
    max-width: 500px;
  }

  .colorCard {
    width: 21.6%;
  }

  .colorCard:nth-of-type(3n) {
    margin-left: 20px;
    margin-right: 0px;
  }

  .colorCard:nth-of-type(4n) {
    margin-right: 20px;
    margin-left: 0px;
  }

  .colorCard:nth-of-type(5n) {
    margin-left: 20px;
  }

  .colorCard:nth-of-type(7n) {
    margin-left: 0px;
  }
}

@media (max-width: 844px) {
  .container {
    max-width: 300px;
  }

  .detailContainer {
    max-width: 90%;
  }

  .colorCard {
    width: 32.4%;
    height: 100px;
  }

  .colorCard:nth-of-type(2n + 1) {
    margin-right: 20px;
    margin-left: 0px;
  }

  .colorCard:nth-of-type(2n) {
    margin-left: 20px;
    margin-right: 0px;
  }
}

@media (max-width: 640px) {
  .container {
    max-width: 300px;
  }

  .detailContainer {
    max-width: 90%;
  }

  .colorCard {
    width: 90%;
  }

  .colorCard:nth-of-type(2n + 1) {
    margin-right: 0px;
    margin-left: 0px;
  }

  .colorCard:nth-of-type(2n) {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.header {
  padding: 16px 0 16px 64px;
  position: sticky;
  top: 0;
  background-color: #232323;
  display: flex;
  align-items: center;
  z-index: 1;
  transition: all 300ms ease-in-out;
}

.footer {
  display: none;
}

.content {
  height: calc(100vh - 65px);
  display: flex;
  align-items: center;
}

.content > div {
  display: flex;
  flex-wrap: wrap;
  max-height: 100%;
  align-items: center;
}

.sideDot {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%) scale(15);
  transition: all 300ms;
}

@media (max-width: 1200px) {
  .sideDot {
    transform: translate(-50%) scale(10);
  }
}

@media (max-width: 1000px) {
  .content {
    height: auto;
    display: flex;
    align-items: center;
    padding: 20px 10px;
    padding-bottom: 30px;
  }
}

@media (max-width: 640px) {
  .content {
    padding: 20px 0;
  }

  .sideDot {
    display: none;
  }

  .menu {
    display: none;
  }

  .header {
    justify-content: center;
    padding: 16px;
  }

  .logo {
    margin-right: 0px;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #232323;
    padding: 16px 0px;
    position: fixed;
    width: 100%;
    z-index: 1;
    bottom: 0;
    transition: all 300ms ease-in-out;
  }

  .contentWrapper {
    padding: 0 10px;
  }

  .menuFooter {
  }
}

.name {
  font-size: 20px;
}

.nameArabic {
  font-size: 20px;
  text-align: right;
  margin-top: 8px;
}

.colorValue {
  font-size: 12px;
}

.detailView {
  position: fixed;
  z-index: 100;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0;
}

@media (max-width: 640px) {
  .detailView {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.overlay {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
}
</style>
