<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">
        Home
      </router-link> |
      <router-link to="/about">
        About
      </router-link>
    </div> -->
    <transition
      name="slide-fade"
    >
      <div
        v-show="displayAbout"
        ref="about"
        :class="$style.aboutWrapper"
      >
        <About @close="displayAbout = false" />
      </div>
    </transition>
    <div
      :class="$style.about"
      @click="displayAbout = true"
    >
      About
    </div>
    <router-view />
  </div>
</template>

<script>
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import anime from 'animejs'
import About from '@/components/About'

export default {
  components: {
    About,
  },
  data() {
    return {
      displayAbout: false,
    }
  },
  mounted() {
    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: 'AIzaSyBi66KEdm8KF_lVuSd50bzGlM56j2yOXWs',
      authDomain: 'moroccolors-9530f.firebaseapp.com',
      projectId: 'moroccolors-9530f',
      storageBucket: 'moroccolors-9530f.appspot.com',
      messagingSenderId: '23737136378',
      appId: '1:23737136378:web:1c5711b5704c8aa0914543',
      measurementId: 'G-H4XKJRLGVL',
    }

    // Initialize Firebase
    const app = initializeApp(firebaseConfig)
    const analytics = getAnalytics(app)
  },
}
</script>

<style lang="scss" module>
.about {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 20px;
  right: 64px;
  z-index: 10;
  cursor: pointer;
}

.aboutWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

@media (max-width: 710px) {
 .about {
   position: fixed;
    font-size: 14px;
    top: inherit;
    bottom: 20px;
    right: 14px;
}
}
</style>
