<template>
  <div
    ref="description"
    :class="$style.wrapper"
  >
    <span
      class="close rounded black"
      :class="$style.closeButton"
      @click="close"
    />
    <KinesisContainer
      :class="$style.image"
      :style="{ backgroundColor: color.value }"
    >
      <div :class="$style.imageContainer">
        <KinesisElement
          ref="image"
          tag="img"
          :src="color.image"
          :style="{ borderRadius: color.imageRadius ? '10px' : '' }"
          alt=""
        />
      </div>
    </KinesisContainer>
    <div :class="$style.description">
      <div>
        <div :class="$style.name">
          {{ color.name }}
        </div>
        <div
          class="arabic"
          :class="$style.nameArabic"
        >
          {{ color.arabic }}
        </div>
        <div :class="$style.descriptionText">
          {{ color.description }}
        </div>
      </div>
      <div :class="$style.colorContainer">
        <CopyToClipboard
          :style="{ backgroundColor: color.value, color: color.textColor }"
          :class="$style.color"
          :text="color.value"
        />
      </div>
    </div>
  </div>
</template>

<script>
import colors from '@/data/colors'
import CopyToClipboard from '@/components/CopyToClipboard'
import ClickOutside from 'vue-click-outside'
import position from '@/utils/position'
import anime from 'animejs'

export default {
  name: 'ColorDetail',
  components: {
    CopyToClipboard,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      position,
    }
  },
  computed: {
    color() {
      const colorGroupName = this.$route.params.colors
      const colorId = this.$route.params.color

      const selectedColorGroup = colors.filter(
        (colorGroup) => colorGroup.name === colorGroupName,
      )

      const selectedColor = this.$store.getters.selectedColor
        || selectedColorGroup[0].colors.filter(
          (colorGroup) => colorGroup.id === colorId,
        )[0]

      return selectedColor
    },
    descriptionOrigin() {
      return this.$store.getters.descriptionOrigin
    },
  },
  mounted() {
    const { description } = this.$refs
    const { image } = this.$refs

    const soThis = this

    document.querySelector('body').style.overflowY = 'hidden'

    image.$el.addEventListener('load', () => {
      const descriptionPosition = position(soThis.$refs.description)

      anime({
        targets: description,
        opacity: [0, 1],
        width: [0, descriptionPosition.width],
        height: [0, descriptionPosition.height],
        translateX: [descriptionPosition.left, 0],
        easing: 'easeInOutExpo',
        duration: 500,
        complete() {
          description.style.width = 'auto'
          description.style.height = 'auto'
        },
      })
    })
  },
  methods: {
    close() {
      const { description } = this.$refs
      const sothis = this

      anime({
        targets: description,
        opacity: 0,
        width: 0,
        height: 0,
        easing: 'easeInOutExpo',
        duration: 500,
        complete() {
          description.style.width = 'auto'
          description.style.height = 'auto'
          sothis.$emit('close')
        },
      })
    },
  },
}
</script>

<style lang="scss" module>
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: ". .";
  background-color: #f6f6f6;
  color: black;
  border-radius: 10px;
  overflow: hidden;
  opacity: 0;
}

.image {
  padding: 96px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 640px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
      "."
      ".";
  }

  .image {
    padding: 40px 0px;
  }
}

.name {
  font-size: 30px;
}

.nameArabic {
  text-align: right;
  font-size: 30px;
}

.descriptionText {
  margin-top: 24px;
  line-height: 20px;
}

.color {
  padding: 12px 20px;
  border-radius: 40px;
  color: white;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.colorContainer {
  position: relative;
  text-align: right;
}

.imageContainer {
  background-color: rgba(255, 255, 255, 1);
  height: 200px;
  width: 200px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    transform: translate(10px, 10px);
  }
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  z-index: 10;
}
</style>
